import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar: React.FC = (): JSX.Element => {
    return (
        <>
            {/* Logo */}
            <div className="flex items-center flex-shrink-0 px-4">
                <div className="inline-flex items-center">
                    <svg className="h-8 w-auto" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 45">
                        <rect y=".002" width="44" height="44" rx="16" fill="#40BFFF" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M31.062 20.274a2.444 2.444 0 010 3.457l-7.334 7.333a2.444 2.444 0 01-3.456 0l-7.334-7.333a2.444 2.444 0 010-3.457l7.334-7.333a2.444 2.444 0 013.457 0l7.333 7.333zM22 18.126l-3.876 3.876L22 25.88l3.876-3.877L22 18.126z"
                            fill="#fff"
                        />
                    </svg>
                    <div className="ml-2 font-bold select-none">E-Shop</div>
                </div>
            </div>

            <nav className="mt-5 flex-1 flex flex-col divide-y divide-gray-400 overflow-y-auto">
                <div className="px-2 space-y-1 text-gray-600">
                    <NavLink
                        exact
                        to="/admin"
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 101 101"
                        >
                            <path
                                d="M89.5 14.962h-79a1.5 1.5 0 00-1.5 1.5V65.65a1.5 1.5 0 001.5 1.5h28.233l-1.405 14.753c-.004.045.001.089.001.134h-7.451a1.5 1.5 0 100 3h41.736a1.5 1.5 0 100-3h-8.197c0-.045.005-.088.001-.134L62.013 67.15H89.5a1.5 1.5 0 001.5-1.5V16.46a1.5 1.5 0 00-1.5-1.5zM60.416 82.038H40.328l1.418-14.887h17.253l1.417 14.887zM88 64.152H12v-46.19h76v46.19z"
                                fill="currentColor"
                            />
                        </svg>
                        Dashboard
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/news"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <path
                                d="M89.81 73H74V32a12 12 0 00-12-12H18.5c-.23 0-.46-.06-.7-.08a12.272 12.272 0 00-9.13 3.26 11.2 11.2 0 00-3.59 8.22 1.5 1.5 0 001.5 1.5H26v40.81A12.13 12.13 0 0037.19 86h42.16a11.75 11.75 0 0012-11.5 1.5 1.5 0 00-1.54-1.5zM8.22 29.9a8.3 8.3 0 012.51-4.53A9.17 9.17 0 0116.15 23c.116.014.234.014.35 0h1.9a9.05 9.05 0 017.33 6.9H8.22zM37.37 83A9.13 9.13 0 0129 73.71V32.19A12.45 12.45 0 0025 23h37a9 9 0 019 9v41H48.42a1.5 1.5 0 00-1.5 1.5 8.23 8.23 0 01-2.65 6 9.26 9.26 0 01-6.9 2.5zm42 0H46l.31-.28A11.24 11.24 0 0049.82 76h38.35a8.86 8.86 0 01-8.82 7h.02z"
                                fill="currentColor"
                            />
                            <path
                                d="M36.5 42h27a1.5 1.5 0 100-3h-27a1.5 1.5 0 100 3zM36.5 51h27a1.5 1.5 0 100-3h-27a1.5 1.5 0 100 3zM52 58.5a1.5 1.5 0 00-1.5-1.5h-14a1.5 1.5 0 100 3h14a1.5 1.5 0 001.5-1.5z"
                                fill="currentColor"
                            />
                        </svg>
                        News
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/users"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 96 72"
                        >
                            <path
                                d="M65.1 39.7c-16.928 0-30.7 13.771-30.7 30.7a1.5 1.5 0 001.5 1.5h58.4a1.5 1.5 0 001.5-1.5c0-16.928-13.773-30.7-30.7-30.7zM37.44 68.9c.782-14.578 12.889-26.2 27.66-26.2s26.878 11.622 27.66 26.2H37.44zM64.5 35.6c9.76 0 17.7-7.94 17.7-17.7C82.2 8.14 74.26.2 64.5.2c-9.76 0-17.7 7.94-17.7 17.7 0 9.76 7.94 17.7 17.7 17.7zm0-32.4c8.105 0 14.7 6.594 14.7 14.7 0 8.105-6.595 14.7-14.7 14.7s-14.7-6.594-14.7-14.7c0-8.106 6.595-14.7 14.7-14.7zM23.4 50.2c3.623 0 7.16.965 10.234 2.79a1.5 1.5 0 001.533-2.58 23.046 23.046 0 00-11.766-3.21C10.607 47.2.2 57.608.2 70.4a1.5 1.5 0 001.5 1.5h27a1.5 1.5 0 100-3H3.255c.77-10.44 9.511-18.7 20.145-18.7zM23.1 42.6c7.499 0 13.6-6.1 13.6-13.6 0-7.499-6.101-13.6-13.6-13.6S9.5 21.501 9.5 29c0 7.5 6.101 13.6 13.6 13.6zm0-24.2c5.845 0 10.6 4.755 10.6 10.6s-4.755 10.6-10.6 10.6S12.5 34.845 12.5 29s4.755-10.6 10.6-10.6z"
                                fill="currentColor"
                            />
                        </svg>
                        Users
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/products"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 101 101"
                            className="mr-4 h-7 w-7"
                        >
                            <path
                                d="M21.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM29.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM34.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM63.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM68.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM45.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM55.5 33a1.5 1.5 0 00-1.5 1.5v32a1.5 1.5 0 103 0v-32a1.5 1.5 0 00-1.5-1.5zM82 66.5v-32a1.5 1.5 0 10-3 0v32a1.5 1.5 0 103 0zM10.5 38a1.5 1.5 0 001.5-1.5V28h8.5a1.5 1.5 0 100-3h-10A1.5 1.5 0 009 26.5v10a1.5 1.5 0 001.5 1.5zM91.5 25h-10a1.5 1.5 0 100 3H90v8.5a1.5 1.5 0 103 0v-10a1.5 1.5 0 00-1.5-1.5zM20.5 74H12v-7.5a1.5 1.5 0 10-3 0v9a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 100-3zM91.5 65a1.5 1.5 0 00-1.5 1.5V74h-8.5a1.5 1.5 0 100 3h10a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-1.5-1.5z"
                                fill="currentColor"
                            />
                        </svg>
                        Products
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/categories"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <path
                                d="M87.5 59H80V27.5a1.5 1.5 0 00-1.5-1.5H67V11.5a1.5 1.5 0 00-1.5-1.5h-31a1.5 1.5 0 00-1.5 1.5V26H21.5a1.5 1.5 0 00-1.5 1.5V59h-7.5a1.5 1.5 0 00-1.5 1.5v19a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5v-19a1.5 1.5 0 00-1.5-1.5H23V29h10v13.5a1.5 1.5 0 001.5 1.5H49v15h-8.5a1.5 1.5 0 00-1.5 1.5v19a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5v-19a1.5 1.5 0 00-1.5-1.5H52V44h13.5a1.5 1.5 0 001.5-1.5V29h10v30h-8.5a1.5 1.5 0 00-1.5 1.5v19a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5v-19a1.5 1.5 0 00-1.5-1.5zM30 78H14V62h16v16zm28 0H42V62h16v16zm6-37H36V13h28v28zm22 37H70V62h16v16z"
                                fill="currentColor"
                            />
                        </svg>
                        Product Categories
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/countries"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 101 101"
                        >
                            <path
                                d="M69.55 58.903h-.016a1.502 1.502 0 01-1.273-.732L54.003 34.226a1.49 1.49 0 01-.103-.208 18.608 18.608 0 01-2.716-9.693c.018-10.31 8.414-18.689 18.72-18.689h.023a18.58 18.58 0 0113.226 5.501 18.595 18.595 0 015.453 13.238c0 3.378-.934 6.705-2.704 9.642a1.442 1.442 0 01-.134.277L70.823 58.2a1.508 1.508 0 01-1.273.704zm-12.83-25.98L69.58 54.524 83.057 32.97c.034-.092.077-.182.13-.267 1.582-2.527 2.418-5.407 2.418-8.328v-.002a15.614 15.614 0 00-4.578-11.119 15.607 15.607 0 00-11.104-4.618h-.02c-8.655 0-15.705 7.035-15.719 15.692 0 2.954.831 5.836 2.402 8.332.054.083.098.172.133.264z"
                                fill="currentColor"
                            />
                            <path
                                d="M69.909 31.352h-.016a7.857 7.857 0 01-5.596-2.323 7.868 7.868 0 01-2.315-5.61 7.86 7.86 0 012.326-5.594 7.873 7.873 0 015.595-2.312h.008c4.365.005 7.914 3.563 7.91 7.932a7.864 7.864 0 01-2.326 5.597 7.862 7.862 0 01-5.586 2.31zm-.007-12.839a4.89 4.89 0 00-3.476 1.437 4.878 4.878 0 00-1.444 3.473 4.893 4.893 0 001.439 3.487 4.878 4.878 0 003.475 1.442h.01a4.882 4.882 0 003.47-1.434 4.88 4.88 0 001.445-3.475 4.929 4.929 0 00-4.914-4.929h-.005zm6.419 4.931h.01-.01zM94.4 80.181H4.54a1.5 1.5 0 01-1.379-2.09l16.345-38.242a1.501 1.501 0 011.38-.911h38.567a1.5 1.5 0 110 3H21.876L6.813 77.18h85.313L77.528 43.03a1.5 1.5 0 112.76-1.179l15.49 36.239a1.502 1.502 0 01-1.379 2.091z"
                                fill="currentColor"
                            />
                            <path
                                d="M69.727 79.912a1.5 1.5 0 01-1.488-1.33c-.01-.074-.967-7.454-11.3-9.207-.961-.163-2.494-.312-4.27-.483-9.78-.946-18.7-2.174-20.42-6.747-1.11-2.95.513-5.199 1.815-7.006 1.426-1.978 1.896-2.842 1.168-4.083-.461-.786-1.93-1.426-3.35-2.045-2.78-1.212-6.587-2.872-6.607-7.422a1.5 1.5 0 011.493-1.507h.007a1.5 1.5 0 011.5 1.493c.01 2.42 1.824 3.385 4.808 4.686 1.86.811 3.783 1.649 4.738 3.278 1.785 3.044-.011 5.536-1.322 7.355-1.23 1.706-1.958 2.821-1.44 4.195 1.205 3.203 12.48 4.293 17.9 4.818 1.833.177 3.416.33 4.482.511 12.532 2.127 13.734 11.43 13.779 11.825a1.5 1.5 0 01-1.493 1.669z"
                                fill="currentColor"
                            />
                        </svg>
                        Countries
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/orders"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 73 75"
                        >
                            <path
                                d="M71.428 21.69h-5.5V2.19a1.5 1.5 0 00-1.5-1.5h-24a1.5 1.5 0 00-1.5 1.5v11.5h-9.5a1.5 1.5 0 00-1.5 1.5v19.5h-10v-20.5a1.5 1.5 0 00-1.5-1.5h-14a1.5 1.5 0 100 3h12.5v49.5a1.506 1.506 0 000 .17v.33a9 9 0 1017.94-1h22.12a9.003 9.003 0 008.439 10.022 9.001 9.001 0 009.5-9.023v-.33a1.46 1.46 0 000-.17v-42a1.5 1.5 0 00-1.5-1.5zm-1.5 13h-18v-10h18v10zm-28-31h21v18h-11v-6.5a1.5 1.5 0 00-1.5-1.5h-8.5v-10zm-11 13h18v18h-18v-18zm-7 55a6 6 0 110-12 6 6 0 010 12zm8.5-10a1.476 1.476 0 00-.42.07A9 9 0 0017.928 59V37.69h52V59a9 9 0 00-14.05 2.69h-23.45zm31.5 10a6 6 0 110-12 6 6 0 010 12z"
                                fill="currentColor"
                            />
                        </svg>
                        Orders
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/coupons"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 101 101"
                        >
                            <path
                                d="M54.85 40.543a1.5 1.5 0 00-2.117.132L31.942 64.229a1.5 1.5 0 002.249 1.985L54.98 42.66a1.5 1.5 0 00-.131-2.117zM35.893 50.725a6.837 6.837 0 003.678-3.757 6.835 6.835 0 00-.051-5.261 6.827 6.827 0 00-3.756-3.684c-3.511-1.417-7.525.292-8.95 3.81a6.811 6.811 0 00.06 5.265 6.85 6.85 0 003.763 3.68 6.827 6.827 0 005.256-.054zm-6.262-4.807a3.832 3.832 0 01-.035-2.96 3.894 3.894 0 013.605-2.433c.48 0 .969.09 1.441.28a3.847 3.847 0 012.117 2.076c.406.952.415 2.005.028 2.965a3.853 3.853 0 01-2.073 2.119c-.951.405-2 .416-2.957.03a3.868 3.868 0 01-2.126-2.077z"
                                fill="currentColor"
                            />
                            <path
                                d="M86.57 43.795a1.5 1.5 0 00-1.546-1.457l-8.622.263 1.946-6.125a1.502 1.502 0 00-.976-1.884L63.13 30.067l-13.706-4.354-24.732-7.86a1.504 1.504 0 00-1.884.977L6.068 71.508a1.5 1.5 0 00.977 1.884l26.876 8.54.398 14.06c.023.814.69 1.459 1.499 1.459h.043l50.682-1.43A1.503 1.503 0 0088 94.48l-1.43-50.685zM25.214 21.167l49.82 15.83-15.83 49.82L9.384 70.987l15.83-49.82zm12.062 73.239l-.326-11.513 22.776 7.238a1.499 1.499 0 001.883-.976l13.829-43.523 8.174-.25 1.346 47.68-47.682 1.344z"
                                fill="currentColor"
                            />
                            <path
                                d="M53.864 58.533a6.827 6.827 0 00-5.265.05 6.832 6.832 0 00-3.685 3.759 6.82 6.82 0 00.055 5.26 6.827 6.827 0 003.761 3.68c.83.336 1.7.503 2.568.503.913 0 1.825-.185 2.69-.553a6.826 6.826 0 003.683-3.76 6.83 6.83 0 00-.05-5.258 6.818 6.818 0 00-3.757-3.681zm1.025 7.82a3.849 3.849 0 01-2.075 2.118 3.842 3.842 0 01-2.964.028 3.848 3.848 0 01-2.12-2.075 3.841 3.841 0 01-.032-2.962 3.85 3.85 0 012.077-2.12 3.861 3.861 0 012.97-.027 3.847 3.847 0 012.116 2.073c.404.95.414 2.004.028 2.965z"
                                fill="currentColor"
                            />
                        </svg>
                        Coupon Codes
                    </NavLink>
                    <NavLink
                        activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                        to="/admin/reports"
                        className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                    >
                        <svg
                            className="mr-4 h-7 w-7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 101 101"
                        >
                            <path
                                d="M80.5 11H61V7.5A1.5 1.5 0 0059.5 6h-18A1.5 1.5 0 0040 7.5V11H21.5a1.5 1.5 0 00-1.5 1.5v72a1.5 1.5 0 001.5 1.5h59a1.5 1.5 0 001.5-1.5v-72a1.5 1.5 0 00-1.5-1.5zM43 9h15v6H43V9zm36 74H23V14h17v2.5a1.5 1.5 0 001.5 1.5h18a1.5 1.5 0 001.5-1.5V14h18v69z"
                                fill="currentColor"
                            />
                            <path
                                d="M33.5 34h-5a1.5 1.5 0 100 3h5a1.5 1.5 0 100-3zM40.5 34a1.5 1.5 0 100 3h33a1.5 1.5 0 100-3h-33zM33.5 47h-5a1.5 1.5 0 100 3h5a1.5 1.5 0 100-3zM73.5 47h-33a1.5 1.5 0 100 3h33a1.5 1.5 0 100-3zM33.5 60h-5a1.5 1.5 0 100 3h5a1.5 1.5 0 100-3zM73.5 60h-33a1.5 1.5 0 100 3h33a1.5 1.5 0 100-3z"
                                fill="currentColor"
                            />
                        </svg>
                        Reports
                    </NavLink>
                </div>
                <div className="mt-6 pt-6">
                    <div className="px-2 space-y-1 text-gray-600">
                        <NavLink
                            activeClassName="text-white bg-blue-500 hover:bg-blue-500"
                            to="/admin/settings"
                            className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                        >
                            <svg
                                fill="none"
                                className="mr-4 h-7 w-7"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 93 93"
                            >
                                <path
                                    d="M46 27.5c-10.201 0-18.5 8.299-18.5 18.5S35.799 64.5 46 64.5 64.5 56.201 64.5 46 56.201 27.5 46 27.5zm0 34c-8.547 0-15.5-6.953-15.5-15.5S37.453 30.5 46 30.5 61.5 37.453 61.5 46 54.547 61.5 46 61.5z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M91.225 38.501L79.257 36.69a33.974 33.974 0 00-2.651-6.425l7.176-9.733a1.501 1.501 0 00-.146-1.951L74.42 9.366a1.501 1.501 0 00-1.951-.147l-9.702 7.152a33.925 33.925 0 00-6.466-2.691L54.5 1.776A1.5 1.5 0 0053.017.5H39.985a1.5 1.5 0 00-1.483 1.276L36.701 13.68a33.99 33.99 0 00-6.466 2.691l-9.702-7.152a1.5 1.5 0 00-1.951.147l-9.215 9.215a1.501 1.501 0 00-.147 1.951l7.176 9.733a33.974 33.974 0 00-2.651 6.425L1.777 38.501a1.5 1.5 0 00-1.276 1.483v13.032a1.5 1.5 0 001.275 1.483l12.027 1.82a34.073 34.073 0 002.647 6.341l-7.231 9.808a1.501 1.501 0 00.147 1.951l9.215 9.215a1.5 1.5 0 001.951.147l9.84-7.254a33.973 33.973 0 006.3 2.607l1.829 12.09a1.5 1.5 0 001.483 1.276h13.032a1.5 1.5 0 001.483-1.276l1.829-12.09a33.973 33.973 0 006.3-2.607l9.84 7.254c.597.44 1.426.377 1.951-.147l9.216-9.215a1.5 1.5 0 00.146-1.951L76.55 62.66a33.939 33.939 0 002.647-6.341l12.027-1.82a1.5 1.5 0 001.275-1.483V39.984a1.499 1.499 0 00-1.274-1.483zM89.5 51.726l-11.703 1.771a1.502 1.502 0 00-1.224 1.09 31.012 31.012 0 01-3.103 7.432c-.3.517-.265 1.162.09 1.643l7.04 9.549-7.391 7.391-9.578-7.061a1.499 1.499 0 00-1.637-.093 30.946 30.946 0 01-7.395 3.06 1.5 1.5 0 00-1.094 1.225l-1.78 11.769H41.273l-1.78-11.769a1.502 1.502 0 00-1.094-1.225 30.946 30.946 0 01-7.395-3.06 1.502 1.502 0 00-1.637.093l-9.578 7.061-7.391-7.391 7.04-9.549a1.5 1.5 0 00.09-1.643 30.995 30.995 0 01-3.103-7.432 1.5 1.5 0 00-1.224-1.09L3.498 51.726V41.274l11.65-1.762a1.5 1.5 0 001.226-1.099 30.971 30.971 0 013.099-7.514 1.5 1.5 0 00-.093-1.638l-6.982-9.471 7.391-7.391 9.443 6.961a1.5 1.5 0 001.644.089 30.98 30.98 0 017.55-3.142 1.5 1.5 0 001.095-1.225l1.752-11.583h10.452l1.752 11.583a1.5 1.5 0 001.095 1.225 30.997 30.997 0 017.55 3.142 1.499 1.499 0 001.644-.089l9.443-6.961L80.6 19.79l-6.982 9.471a1.5 1.5 0 00-.093 1.638 31.011 31.011 0 013.099 7.514 1.501 1.501 0 001.226 1.099l11.65 1.762v10.452z"
                                    fill="currentColor"
                                />
                            </svg>
                            Settings
                        </NavLink>
                        <a
                            className="group flex items-center px-2 py-2 text-sm leading-6 rounded-md hover:text-white hover:bg-blue-400 select-none"
                            href="/"
                            target="_blank"
                        >
                            <svg
                                className="mr-4 h-7 w-7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                            >
                                <path
                                    d="M90.2 22.27L77.73 9.8a4.44 4.44 0 00-6.28 0L47.8 33.45a4.441 4.441 0 000 6.28L53 44.9l-8.57 8.57-5.69-5.67a4.45 4.45 0 00-6.29 0L8.8 71.44a4.45 4.45 0 000 6.29L21.26 90.2a4.46 4.46 0 006.29 0L51.2 66.56a4.45 4.45 0 000-6.29l-4.67-4.67L55.1 47l5.17 5.17a4.442 4.442 0 006.28 0L90.2 28.55a4.438 4.438 0 000-6.28zM49.08 62.38a1.45 1.45 0 010 2.05L25.43 88.08a1.45 1.45 0 01-2.05 0L10.92 75.62a1.45 1.45 0 010-2.05l23.65-23.65a1.45 1.45 0 012.05 0l5.67 5.67-6.85 6.85a1.5 1.5 0 102.12 2.12l6.85-6.85 4.67 4.67zm39-36l-23.65 23.7a1.47 1.47 0 01-2 0l-5.21-5.18 7.34-7.34a1.5 1.5 0 00-2.12-2.12l-7.34 7.34-5.17-5.17a1.44 1.44 0 010-2l23.64-23.69a1.44 1.44 0 012 0l12.51 12.47a1.44 1.44 0 010 2v-.01z"
                                    fill="currentColor"
                                />
                            </svg>
                            Open Store
                            <small className="ml-2 py-px px-2 bg-white font-medium rounded-md border border-red-400 text-red-400 align-top">
                                New Tab
                            </small>
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Sidebar;
